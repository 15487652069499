import textSegmentation from '../apis/textSegmentation';
import {
    SET_TOPIC_MAPPINGS,
    SET_TOPIC_COLORS,
    SET_SETTINGS,
    ADD_SELECTED_TOPIC,
    REMOVE_SELECTED_TOPIC,
    SELECT_TOPIC,
    DESELECT_TOPIC,
    ACCEPT_AGREEMENT,
    SET_DOCUMENT_TYPE,
    SET_DOCUMENT_TYPES,
    TOPICIFY,
    SHOW_NOTIFICATION,
    CLEAR,
    SELECT_ALL_TOPICS,
    DESELECT_ALL_TOPICS,
    SET_DEFAULT_DOCUMENT_TYPE,
    TREE_TOGGLE_CHILDREN,
    TREE_SELECT_NODE,
    TREE_UPDATE_STORE,
    TREE_INITIALIZE
} from './actionTypes';

export const setTopicMappings = (topicMapping) => ({
    type: SET_TOPIC_MAPPINGS,
    topicMapping
});

export const setTopicColors = (topicColors) => ({
    type: SET_TOPIC_COLORS,
    topicColors
});

export const setSettings = (settings) => ({
    type: SET_SETTINGS,
    settings
});

export const addSelectedTopic = (topic) => ({
    type: ADD_SELECTED_TOPIC,
    topic
});

export const removeSelectedTopic = (topic) => ({
    type: REMOVE_SELECTED_TOPIC,
    topic
});

export const selectAllTopics = () => ({
    type: SELECT_ALL_TOPICS
});

export const deselectAllTopics = () => ({
    type: DESELECT_ALL_TOPICS
});

export const selectTopic = (topicIdx) => ({
    type: SELECT_TOPIC,
    selectedTopic: topicIdx
});

export const deselectTopic = () => ({
    type: DESELECT_TOPIC
})

export const acceptAgreement = () => ({
    type: ACCEPT_AGREEMENT
});

export const setDocumentTypes = (documentTypes) => ({
    type: SET_DOCUMENT_TYPES,
    documentTypes
});

export const setDocumentType = (docType) => ({
    type: SET_DOCUMENT_TYPE,
    docType
});

export const setDefaultDocumentType = (defaultDocType) => ({
    type: SET_DEFAULT_DOCUMENT_TYPE,
    defaultDocType
});

export const topicify = (url, text) => async (dispatch, getState) => {
    const { settings, docType } = getState();
    const response = await textSegmentation.post(url, {
        text,
        ...settings,
        documentType: docType
    });
    dispatch({
        type: TOPICIFY,
        payload: response
    });
    dispatch({
        type: SHOW_NOTIFICATION,
        isShown: true
    });
    dispatch({
        type: SET_DOCUMENT_TYPE,
        docType: response.data.document_type
    });
    dispatch({
        type: TREE_INITIALIZE,
        collapsibleTree: response.data.link_mapping
    })
};

export const showNotification = (isShown) => ({
    type: SHOW_NOTIFICATION,
    isShown
});

export const clear = () => (dispatch, getState) => {
    const { defaultDocType } = getState();
    dispatch({
        type: SET_DOCUMENT_TYPE,
        docType: defaultDocType
    });
    dispatch({
        type: CLEAR
    });
};

// ///////////////////////////
// Collapsible tree actions //
// ///////////////////////////
export const toggleChildren = (topicId, nodeId) => ({
    type: TREE_TOGGLE_CHILDREN,
    topicId,
    nodeId
});

export const selectNode = (topicId, nodeId) => ({
    type: TREE_SELECT_NODE,
    topicId,
    nodeId
});

export const updateStore = (topicId, store) => ({
    type: TREE_UPDATE_STORE,
    topicId,
    store
});
