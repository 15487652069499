import React from 'react';
import { connect } from 'react-redux';
import treeUtils from '../collapsible-tree/treeUtils';
import CollapsibleTree from '../collapsible-tree';
import './styles.css';

class TopicDetails extends React.PureComponent {
    state = {
        linksOpen: false,
        selected: null,
        items: null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.collapsibleTree !== prevProps.collapsibleTree) {
            this.setState({
                items: this.props.collapsibleTree[this.props.topicId]
            })
        }
    }

    onSelection = id => {
        this.setState(state => ({
            selected: id,
            items: treeUtils.toggleItemsToId(state.items, id, state.selected)
        }));
    }

    handleToggleLinks() {
        this.setState({ linksOpen: !this.state.linksOpen });
    }

    render() {
        return (
            <>
                <div className="d-flex justify-content align-items-center topic-detail no-select">
                    <label className="custom-control m-1 custom-checkbox-container">
                        <input type="checkbox"
                               checked={this.props.checked}
                               readOnly
                               id={this.props.id}
                               onClick={this.props.onClick}
                        />
                        <span
                            className="checkmark"
                            style={this.props.checked ? { backgroundColor: this.props.backgroundColor } : {}}
                        />
                    </label>

                    <div style={{ width: '100%', minHeight: 55, textAlign: 'left' }} className="d-flex align-items-center justify-content-left">
                        {this.props.children}
                    </div>
                </div>

                {
                    this.state.items &&
                    <>
                        <button className="d-flex align-items-center menuitem link-parent-node"
                                onClick={() => this.handleToggleLinks()}>
                            Ссылки на законодательство
                            {this.state.linksOpen && <i className="arrow down ml-1" style={{borderColor: 'orange'}}/>}
                            {!this.state.linksOpen &&
                            <i className="arrow right ml-1" style={{borderColor: 'orange', marginTop: 2}}/>}
                        </button>
                        {this.state.linksOpen &&
                            <CollapsibleTree
                                items={this.state.items} // items
                                topIds={Object.keys(this.state.items).filter(el => !el.includes('-'))} // array containing all top level item ids
                                selection={"0"} // selection
                                topicId={this.props.topicId}
                                onSelection={this.onSelection}
                                indent={5}
                            />
                        }
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    collapsibleTree: state.collapsibleTree
});

export default connect(mapStateToProps)(TopicDetails);
