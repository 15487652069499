import React from 'react';
import { connect } from 'react-redux';
import Node from './components/node';
import { toggleChildren, updateStore } from '../../../../../actions';

import './styles.css';

class Tree extends React.Component {
    componentDidMount() {
        if (this.props.selection) {
            this.props.toggleChildren(this.props.topicId ,this.props.selection);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.items !== nextProps.items) {
            return true;
        }
        return this.props.topIds !== nextProps.topIds;
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items) {
            this.props.updateStore(this.props.topicId, this.props.items);
        }
    }

    render() {
        return (
            <div className="collapsible-tree-container">
                {this.props.topIds.map(topId => (
                    <Node
                        indent={this.props.indent}
                        className={this.props.className}
                        id={topId}
                        key={topId}
                        topicId={this.props.topicId}
                        onSelection={this.props.onSelection}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.collapsibleTree
})

const ConnectedTree = connect(mapStateToProps, { toggleChildren, updateStore })(Tree);
export default ConnectedTree;
