import i18n from 'i18next';
import configs from './configs';

i18n.init({
    // we init with resources
    lng: configs.LANG,
    resources: {
        en: {
            translations: {
                ButtonAbout: "About",
                ButtonAccept: "Accept",
                ButtonReject: "Reject",
                ButtonOk: "OK",
                ButtonCancel: "Cancel",
                ButtonChangeType: "Change type",
                ButtonFindTopics: "Find contract clauses",
                ButtonUpdate: "Update",
                ButtonClassifyText: "Classify text",
                ButtonCompareTexts: "Compare texts",
                ButtonMakeNewAnalysis: "Make new analysis",
                ButtonSaveChanges: "Save changes",

                LabelMaxSplits: "Max splits",
                LabelTypeText: "Type text",
                LabelLoadByUrl: "Load by URL",
                LabelThreshold: "Threshold",
                LabelFrequency: "Frequency",
                LabelTypeOfSegmentation: "Type of segmentation",
                LabelSegmentationLength: "Segmentation length",
                LabelContextWindow: "Context window",
                LabelContextGap: "Context gap",
                LabelEnterText: "Enter text",
                LabelSettings: "Settings",
                LabelSelectAll: "Select all clauses",
                LabelChangeDocumentType: "To refine the search for document's conditions, specify the type of document manually",

                LinkLicenseAgreement: "To process the document you need to accept the conditions of ",
                LicenseAgreement: "License agreement",
                LicenseAgreementLink: "License agreement",

                PlaceholderEnterURL: "Enter URL...",

                SelectParagraph: "Paragraph",
                SelectContextGreedy: "Context greedy",
                SelectContextOptimal: "Context optimal",
                SelectContextByWords: "Context by words",
                SelectSentence: "Sentence",
                SelectWhole: "Whole",

                TabSelectText: "Select text",
                TabSettings: "Settings",
                Topics: "Contract clauses",
                TabCompare: "Compare",
                TabClassify: "Classify",

                TextAreaPlaceholder: "To analyze contract please paste its text here and click FIND CONTRACT CLAUSES",

                TitleChangeDocumentType: "Select document type",
                TitleNotification: "Notification",

                MsgAllEssentialConditionsFulfilled: "All essential provisions are found in this document",
                MsgAllEssentialConditionsNotFulfilled: "Essential provisions {{conditions}} are not found in this document",
                MsgAcceptLicense: "You must accept license agreement before analysis of contract",

                Result: "Result",
                NoTopics: "No provisions found",
                EmptySidebarMsg: "Please paste contract text.",
                MovePrev: "Move to previous clause",
                MoveNext: "Move to next clause",
                MoveDisabled: "Disabled until clause is selected"
            }
        },

        ru: {
            translations: {
                ButtonAbout: "О программе",
                ButtonAccept: "Принимаю",
                ButtonReject: "Не принимаю",
                ButtonOk: "ОК",
                ButtonCancel: "Отмена",
                ButtonChangeType: "Изменить тип",
                ButtonFindTopics: "Найти условия договора",
                ButtonUpdate: "Обновить",
                ButtonClassifyText: "Классифицировать текст",
                ButtonCompareTexts: "Сравнить тексты",
                ButtonMakeNewAnalysis: "Новый анализ",
                ButtonSaveChanges: "Сохранить изменения",

                LabelMaxSplits: "Максимальное число разбиений",
                LabelTypeText: "Ввести текст",
                LabelLoadByUrl: "Загрузить по ссылке",
                LabelThreshold: "Пороговое значение",
                LabelFrequency: "Частота",
                LabelTypeOfSegmentation: "Тип сегментации",
                LabelSegmentationLength: "Длина сегмента",
                LabelContextWindow: "Констекстное окно",
                LabelContextGap: "Контекстный сдвиг",
                LabelEnterText: "Введите текст",
                LabelSettings: "Настройки",
                LabelSelectAll: "Выбрать все условия",
                LabelChangeDocumentType: "Для уточнения поиска условий договора укажите тип договора вручную",

                LinkLicenseAgreement: "Для обработки документа Вам нужно принять условия ",
                LicenseAgreement: "Пользовательского соглашения",
                LicenseAgreementLink: "Пользовательское соглашение",

                PlaceholderEnterURL: "Введите ссылку...",

                SelectParagraph: "Абзац",
                SelectContextGreedy: "Контекст жадный",
                SelectContextOptimal: "Контекст оптимальный",
                SelectContextByWords: "Контекст по словам",
                SelectSentence: "Предложение",
                SelectWhole: "Целый",

                TabSelectText: "Выбрать текст",
                TabSettings: "Настройки",
                Topics: "Условия договора",
                TabCompare: "Сравнить",
                TabClassify: "Классифицировать",

                TextAreaPlaceholder: "Для того, чтобы проанализировать договор - вставьте его текст и нажмите кнопку НАЙТИ УСЛОВИЯ ДОГОВОРА.",

                TitleChangeDocumentType: "Выбрать тип договора",
                TitleNotification: "Уведомление",

                MsgAllEssentialConditionsFulfilled: "В договоре предусмотрены все существенные условия",
                MsgAllEssentialConditionsNotFulfilled: "В договоре отсутству{{letter}}т {{conditions}}",
                MsgAcceptLicense: "Обработка договора невозможна без принятия пользовательского соглашения",

                Result: "Результат",
                NoTopics: "Нет выделенных условий.",
                EmptySidebarMsg: "Пожалуйста, введите текст договора.",
                MovePrev: "Предыдущее условие",
                MoveNext: "Следующее условие",
                MoveDisabled: "Отключено пока не выбрано условие"
            }
        }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
