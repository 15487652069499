import React from 'react';
import config from '../../configs/config';

class AboutModal extends React.Component {

    render() {
        return (
            <>
                <div className="modal-body" dangerouslySetInnerHTML={{ __html: config.about }} />
                <div className="modal-footer">
                    <button
                        type="button"
                        className="app-button"
                        onClick={() => this.props.close()}>
                        OK
                    </button>
                </div>
            </>);
    }
}

export default AboutModal;
