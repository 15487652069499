import React from 'react'
import { connect } from 'react-redux';
import Topics from './components/topics';
import i18next from 'i18next';
import './styles.css';


const mapStateToProps = (state) => ({
    topics: state.topicInfo.topics,
});

class Sidebar extends React.Component {
    render() {
        const topics = (
            <div className="position-fixed col-md-auto d-flex align-items-start" style={{ overflow: 'auto', height: '90%' }}>
                <div className="text-center vertical-center">
                    <Topics/>
                </div>
            </div>
        );

        const noTopics = (
            <div className="position-fixed h-100 col-md-auto d-flex align-items-center">
                <div className="text-center vertical-center">
                    <div>
                        { i18next.t('NoTopics') }
                    </div>
                    <div>
                        { i18next.t('EmptySidebarMsg') }
                    </div>
                </div>
            </div>
        );

        return (
            <nav id="sidebar">
                <div className="sidebar-header">
                    <h3>{ i18next.t('Topics') }</h3>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md">
                            <div className="container">
                                <div className="row justify-content-md-center">
                                    { this.props.topics && this.props.topics.length ? topics : noTopics}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default connect(mapStateToProps, null)(Sidebar);
