//////////////////////////////////
// Color utils
//////////////////////////////////

function generateRandomColor(avoidDark=true) {
    const letters = '0123456789ABCDEF';
    const hexColor = Array.from({ length: 6}, () => letters[Math.floor(Math.random()*16)])
        .reduce((prev, curr) => (prev + curr), '#');
    if (avoidDark && checkDarkColor(hexColor)) return generateRandomColor(); // try once more if color is too dark
    return hexColor;
}

// see https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
function checkDarkColor(hexColor) {
    const rgbColor = parseInt(hexColor.substring(1), 16);
    const [r, g, b] = [(rgbColor >> 16) & 0xff, (rgbColor >> 8) & 0xff, (rgbColor >> 0) & 0xff];
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 60;
}

export { generateRandomColor, checkDarkColor }


//////////////////////////////////
// Numerical and functional utils
//////////////////////////////////

function argmax(arg) {
    // arg is expected to be either an array or an object
    if (Array.isArray(arg)) {
        return arg.map((el, i) => [el, i]).reduce((r, a) => (a[0] > r[0] ? a : r), [Number.NEGATIVE_INFINITY, -1])[1];
    }

    // returns key of the biggest value in object. All values must be numbers
    return Object.keys(arg)
        .map(key => [Number(arg[key]), key]).reduce((r, a) => (a[0] > r[0] ? a : r), [Number.NEGATIVE_INFINITY, -1])[1];
}

// filters object's {key:value} pairs leaving only those that passed the predicate function condition
function filter(obj, predicate, byKey=false) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && predicate(byKey ? key : obj[key])) {
            result[key] = obj[key];
        }
    }
    return result;
}

function merge(obj1, obj2) {
    return {...obj1, ...obj2};
}

// Note: may be not working as expected on arrays with duplicates
function arrayIntersect(arr1, arr2) {
    if (!arr1) return [];
    if (!arr2) return [];
    return arr1.filter(el => arr2.includes(el));
}

// This should be faster than JSON.stringify(arr1) === JSON.stringify(arr2)
function arraysEqual(arr1, arr2) {
    if(arr1.length !== arr2.length) return false;
    for(let i = arr1.length; i--;) {
        if(arr1[i] !== arr2[i]) return false;
    }
    return true;
}

function arrayCopy(arr) {
    return arr.slice(0);
}

export { argmax, filter, merge, arrayIntersect, arraysEqual, arrayCopy }

//////////////////////////////////
// String utils
//////////////////////////////////

function replaceAt(str, begin, end, replacement) {
    return str.substring(0, begin) + replacement + str.substring(end, str.length);
}

export { replaceAt }

//////////////////////////////////
// React helpers
//////////////////////////////////

function generateKey(data, clip=10) {
    const letters = '0123456789ABCDEF';
    const uuid = Array.from({ length: 6}, () => letters[Math.floor(Math.random()*16)])
        .reduce((prev, curr) => (prev + curr), '');
    return `${data ? data.substring(0, clip) : ''}_${uuid}_${new Date().getTime()}`
}

export { generateKey }
