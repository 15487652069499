import {combineReducers} from 'redux';
import agreement from './agreement';
import documentType from './documentType';
import documentTypes from './documentTypes';
import defaultDocumentType from './defaultDocumentType';
import notification from './notification';
import settings from './settings';
import topicColors from './topicColors';
import topicInfo from './topicInfo';
import topicMappings from './topicMappings';
import collapsibleTree from './collapsibleTree';

export default combineReducers({
    isAgreementAccepted: agreement,
    docType: documentType,
    documentTypes,
    defaultDocType: defaultDocumentType,
    showNotification: notification,
    settings,
    topicColors,
    topicInfo,
    topicMapping: topicMappings,
    collapsibleTree
});
