/**
 * This reducer is responsible for the defaultDocType part of the store.
 * Default doc type is returned from the server.
 */

import {SET_DEFAULT_DOCUMENT_TYPE} from '../actions/actionTypes';

export default (state='', action) => {
    if (action.type === SET_DEFAULT_DOCUMENT_TYPE) {
        return action.defaultDocType;
    }
    return state;
};
