import React, { useState } from 'react';
import Modal from '../modal';
import SettingsModal from '../modal/settings-modal';
import AboutModal from '../modal/about-modal';
import i18next from 'i18next';
import styles from './styles.module.css';

import configs from '../configs';
import config from '../configs/config';
const { COMPONENTS } = configs;

function TopMenuNavbar() {
    const [settingsOpened, setSettingsOpened] = useState(false);
    const [aboutOpened, setAboutOpened] = useState(false);

    return (
        <div className={styles['top-menu-navbar']}>
            <nav className={`navbar navbar-expand-lg ${styles['top-menu']}`}>
                <h2>{ config.title }</h2>


                <div className="collapse navbar-collapse justify-content-end">
                    <ul className="navbar-nav">
                        <li className="nav item">
                            { COMPONENTS.aboutButtonVisible &&
                                <button
                                    className="app-button mr-3"
                                    onClick={() => setAboutOpened(true)}>{i18next.t('ButtonAbout')}</button>
                            }
                            { COMPONENTS.settingsVisible &&
                                <i
                                    className={`fa fa-2x fa-cog d-flex align-items-center ${styles['settings-button']}`}
                                    onClick={() => setSettingsOpened(true)}/>
                            }
                        </li>
                    </ul>
                </div>
            </nav>

            { settingsOpened &&
            <Modal
                close={() => setSettingsOpened(false)}
                iconStyles="fa fa-2x fa-cog"
                title={i18next.t('LabelSettings')}
            >
                <SettingsModal
                    close={() => setSettingsOpened(false)}
                />
            </Modal> }

            { aboutOpened &&
            <Modal
                close={() => setAboutOpened(false)}
                title={config.title}
            >
                <AboutModal
                    close={() => setAboutOpened(false)}
                />
            </Modal> }
        </div>
    );
}


export default TopMenuNavbar;
