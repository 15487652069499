import React from 'react';
import { connect } from 'react-redux';
import {setSettings, topicify} from '../../actions';
import Settings from "../../sidebar/components/settings";
import i18next from "i18next";


const mapStateToProps = (state) => ({
    text: state.topicInfo.text,
    settings: state.settings
});

class SettingsModal extends React.Component {
    state = {
        settings: this.props.settings
    };

    handleSubmit = (event) => {
        this.props.close();
        event.preventDefault();
        this.props.setSettings({
            threshold: Number(this.state.settings.threshold),
            fragmentsSize: Number(this.state.settings.fragmentsSize),
            segmentLength: Number(this.state.settings.segmentLength),
            maxSplits: Number(this.state.settings.maxSplits),
            segmentType: this.state.settings.segmentType,
            contextWindow: Number(this.state.settings.contextWindow),
            contextGap: Number(this.state.settings.contextGap)
        });

        this.props.topicify('extractTopicsInText', this.props.text);
    };

    render() {
        return (
            <>
                <div className="modal-body">
                    <Settings
                        settings={this.state.settings}
                        changeSettings={settings => this.setState({ settings })}
                    />
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="app-button"
                        onClick={this.handleSubmit}>
                        { i18next.t('ButtonSaveChanges') }
                    </button>
                </div>
            </>);
    }
}

export default connect(mapStateToProps, { setSettings, topicify })(SettingsModal);
