import React  from 'react';
import i18next from 'i18next';
import './styles.css';

const Settings = ({ settings, changeSettings })  => {
    return (
        <form>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="threshold">{i18next.t('LabelThreshold')}</label>
                        <input onChange={(event) => {changeSettings({...settings, threshold: event.target.value})}}
                               defaultValue={settings.threshold}
                               type="number"
                               className="form-control"
                               id="threshold"
                        />
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="fragments">{i18next.t('LabelFrequency')}</label>
                        <input onChange={(event) => {changeSettings({...settings, fragmentsSize: event.target.value})}}
                               defaultValue={settings.fragmentsSize}
                               type="number"
                               className="form-control"
                               id="fragments"
                        />
                    </div>
                </div>
            </div>


            {
                settings.segmentType === 'Context optimal' &&
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="segment-len">{i18next.t('LabelSegmentationLength')}</label>
                            <input onChange={(event) => {changeSettings({...settings, segmentLength: event.target.value})}}
                                   defaultValue={settings.segmentLength}
                                   type="number"
                                   className="form-control"
                                   id="segment-len"/>
                        </div>
                    </div>
                </div>
            }

            {
                settings.segmentType === 'Context by words' &&
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="context-window">{i18next.t('LabelContextWindow')}</label>
                            <input onChange={(event) => {changeSettings({...settings, contextWindow: event.target.value})}}
                                   defaultValue={settings.contextWindow}
                                   type="number"
                                   className="form-control"
                                   id="context-window"/>
                        </div>
                    </div>

                    <div className="col">
                        <div className="field">
                            <label htmlFor="segment-len">{i18next.t('LabelContextGap')}</label>
                            <input onChange={(event) => {changeSettings({...settings, contextGap: event.target.value})}}
                                   defaultValue={settings.contextGap}
                                   type="number"
                                   className="form-control"
                                   name="segment-len"/>
                        </div>
                    </div>
                </div>
            }

            {
                settings.segmentType === 'Context greedy' &&
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="max-splits">{i18next.t('LabelMaxSplits')}</label>
                            <input onChange={(event) => {changeSettings({...settings, maxSplits: event.target.value})}}
                                   defaultValue={settings.segmentLength}
                                   type="number"
                                   className="form-control"
                                   id="max-splits"/>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="segment-type">{i18next.t('LabelTypeOfSegmentation')}</label>
                        <select onChange={(event) => {changeSettings({...settings, segmentType: event.target.value})}}
                                defaultValue={settings.segmentType}
                                className="custom-select mb-3"
                                name="segment-type"
                        >
                            <option value="Context greedy" onSelect={() => {}}>{i18next.t('SelectContextGreedy')}</option>
                            <option value="Context optimal">{i18next.t('SelectContextOptimal')}</option>
                            <option value="Context by words">{i18next.t('SelectContextByWords')}</option>
                            <option value="Sentence">{i18next.t('SelectSentence')}</option>
                            <option value="Paragraph">{i18next.t('SelectParagraph')}</option>
                            <option value="Whole">{i18next.t('SelectWhole')}</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Settings;
