import React from 'react';
import './styles.css';


function Modal({ close, iconStyles, title, children }) {
    return (
        <div className="modal fade show in">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <i className={iconStyles} />
                        <h5 className="modal-title ml-2">{ title }</h5>
                        <button
                            type="button"
                            className="close"
                            style={{ outline: 'none' }}
                            onClick={close}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    {children}
                </div>
            </div>
            <div className="modal-backdrop fade show" onClick={close} />
        </div>
    );
}

export default Modal;
