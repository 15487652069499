// Contains helper functions specific for the index.js in this directory.
// Usually functions are pure and testable.
// This module is created in order to be able to cover all the stuff (ideally) with the unit tests.

const mapLinesToOriginalTextPositions = lines => {
    let accumulatedLength = 0;
    return lines.map(el => {
        const result = {
            start: accumulatedLength,
            end: accumulatedLength + el.length,
            content: el
        };
        accumulatedLength += (el.length + 1); // 1 for \n symbol
        return result;
    })
};

export { mapLinesToOriginalTextPositions }
