const env = (key, type, defaultValue) => {
    if (!process.env[key]) return defaultValue;

    const value = process.env[key];
    switch (type) {
        case "string":
            return value;
        case "boolean":
            const lower = value.toLowerCase();
            if (["1", "true", "yes", "y", "ok", "on"].includes(lower)) return true;
            if (["0", "false", "no", "n", "nok", "off"].includes(lower)) return false;
            throw new Error(`Invalid env variable ${key}. Expected a boolean: ${value}`);
        case "number":
            const parsed = parseInt(value);
            if (isNaN(parsed)) throw new Error(`Invalid env variable ${key}. Expected a number: ${value}`);
            return parsed;
        default:
            throw new Error(`Unsupported type ${type} for env variable ${key}. Should be 'string', 'boolean' or 'number'`);
    }
}


export default {
    BASE_URL: env('REACT_APP_BASE_URL', 'string'),
    PREFIX: env('REACT_APP_PREFIX', 'string', ''),
    COMPONENTS: {
        settingsVisible: env('REACT_APP_SETTINGS_BUTTON', 'boolean', true),
        changeTypeButtonVisible: env('REACT_APP_CHANGE_TYPE_BUTTON', 'boolean', true),
        aboutButtonVisible: env('REACT_APP_ABOUT_BUTTON', 'boolean', true),
        licenseVisible: env('REACT_APP_LICENSE_VISIBLE', 'boolean', true),
        notificationsEnabled: env('REACT_APP_NOTIFICATIONS', 'boolean' ,true),
        moveButtonsEnabled: env('REACT_APP_MOVE_BUTTONS', 'boolean', false)
    },
    LANG: env('REACT_APP_LANG', 'string', 'ru'),
    DOC_TYPE_ENABLED: env('REACT_APP_DOC_TYPE_ENABLED', 'boolean', true),
    COLORS: ["#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78",
             "#2ca02c", "#98df8a", "#d62728", "#ff9896",
             "#9467bd", "#c5b0d5", "#8c564b", "#c49c94",
             "#e377c2", "#f7b6d2", "#7f7f7f", "#c7c7c7",
             "#bcbd22", "#dbdb8d", "#17becf", "#9edae5"]
}
