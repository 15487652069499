import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import TagManager from 'react-gtm-module';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import setText from './reducers';


if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_TAG) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GA_TAG
    };
    TagManager.initialize(tagManagerArgs);
}


const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : dummy => dummy;
const store = createStore(setText, compose(applyMiddleware(thunk), devTools));

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
