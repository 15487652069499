import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import Modal from '../modal';
import AgreementModal from '../modal/agreement-modal';
import { acceptAgreement, setTopicMappings, topicify } from '../actions';
import { store } from 'react-notifications-component';
import styles from  './styles.module.css';

import configs from '../configs';
const { COMPONENTS, PREFIX } = configs;


class UserInputArea extends React.Component {

    state = {
        content: '',
        agreementModalVisible: false
    };

    handleSubmitManual = (event) => {
        event.preventDefault();
        if (!this.props.isAgreementAccepted && COMPONENTS.licenseVisible) {
            this.setState({ agreementModalVisible: true });
        } else {
            this.props.topicify('/extractTopicsInText', this.state.content);
        }
    };

    handleChange = (event) => {
        this.setState({ content: event.target.value });
    };

    closeAgreementModal = () => {
        this.setState({ agreementModalVisible: false });
    };

    handleAcceptAgreement() {
        localStorage.setItem('SilkContractsAcceptAgreement', 'true');
        this.props.acceptAgreement();
        this.props.topicify('/extractTopicsInText', this.state.content);
    }

    handleRejectAgreement() {
        store.addNotification({
            title: i18next.t('TitleNotification'),
            message: i18next.t('MsgAcceptLicense'),
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5*1000*1000*1000,
                onScreen: true
            }
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmitManual} className={`${styles['form-upload-style']}`}>
                <h3>{i18next.t('LabelEnterText')}</h3>
                <textarea
                    id="text-upload"
                    value={this.state.content}
                    onChange={this.handleChange}
                    className={styles['textarea-upload-style']}
                    placeholder={i18next.t('TextAreaPlaceholder')}
                />

                <div className="d-flex justify-content-center position-relative">
                    <input type="submit" value={i18next.t('ButtonFindTopics')} className="app-button"/>
                    {COMPONENTS.licenseVisible &&
                        <a
                            href={`${PREFIX}/assets/agreement.pdf`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="position-absolute"
                            style={{ fontSize: 12, right: 0 }}
                        >
                            {i18next.t('LicenseAgreementLink')}
                        </a>}
                </div>

                { this.state.agreementModalVisible && (
                    <Modal
                        close={this.closeAgreementModal}
                        iconStyles="fa fa-2x fa-exclamation-circle"
                        title={i18next.t('LicenseAgreement')}
                    >
                        <AgreementModal
                            close={this.closeAgreementModal}
                            agreeCallback={() => this.handleAcceptAgreement()}
                            rejectCallback={() => this.handleRejectAgreement()}
                        />
                    </Modal>)}
            </form>
        );
    }

}

const mapStateToProps = (state) => ({
    settings: state.settings,
    isAgreementAccepted: state.isAgreementAccepted,
    initiallySelectedTopics: state.topicInfo.initiallySelectedTopics,
    mostCommonTopics: state.topicInfo.mostCommonTopics,
    topicMapping: state.topicInfo.topicMapping,
    notificationShown: state.showNotification
});

export default connect(mapStateToProps, { acceptAgreement, setTopicMappings, topicify })(UserInputArea);
