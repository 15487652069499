import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';

const mapStateToProps = state => ({
    settings: state.settings,
    documentTypes: state.documentTypes,
    documentType: state.docType
});

class NewAnalysisModal extends React.Component {
    state = {
        selectedDocumentType: this.props.documentType
    };

    handleAgree = (event) => {
        event.preventDefault();
        this.props.agreeCallback(this.state.selectedDocumentType);
        this.props.close();
    };

    handleReject = (event) => {
        event.preventDefault();
        this.props.rejectCallback();
        this.props.close();
    };

    handleSelect = (event) => {
        this.setState({ selectedDocumentType: event.target.value });
    };

    render() {
        return (
            <>
                <div className="modal-body">
                    <label
                        htmlFor="document-type"
                        className="m-1 d-flex justify-content-center"
                    >
                        {i18next.t('LabelChangeDocumentType')}
                    </label>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <select onChange={this.handleSelect}
                                        value={this.state.selectedDocumentType}
                                        className="custom-select mb-3"
                                        name="document-type"
                                >
                                    {
                                        this.props.documentTypes.map(docType => <option key={docType}>{docType}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="app-button"
                            onClick={this.handleAgree}>
                            {i18next.t('ButtonOk')}
                        </button>
                        <button
                            type="button"
                            className="app-button"
                            onClick={this.handleReject}>
                            {i18next.t('ButtonCancel')}
                        </button>
                    </div>
                </div>
                </>);
    }
}

export default connect(mapStateToProps)(NewAnalysisModal);
