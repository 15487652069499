import {
    ADD_SELECTED_TOPIC,
    CLEAR,
    DESELECT_ALL_TOPICS, DESELECT_TOPIC,
    REMOVE_SELECTED_TOPIC,
    SELECT_ALL_TOPICS, SELECT_TOPIC,
    TOPICIFY
} from '../actions/actionTypes';


const initialState = {
    text: '',
    topics: null,
    initiallySelectedTopics: [],
    mostCommonTopics: [],
    selectedTopics: [],
    selectedTopic: null,
    docType: '',
    userInputArea: 'upload'
};

export default (state=initialState, action) => {
    switch (action.type) {
        case TOPICIFY:
            const data = action.payload.data;
            return {
                topicMapping: data.topic_mapping,
                text: data.original_text,
                topics: data.topics,
                initiallySelectedTopics: data.initially_selected_topics,
                mostCommonTopics: data.most_common_topics,
                selectedTopics: data.most_common_topics.filter(el => data.initially_selected_topics.includes(el)),
                userInputArea: null
            };
        case CLEAR:
            return initialState;
        case ADD_SELECTED_TOPIC:
            return {
                ...state,
                selectedTopics: state.selectedTopics.concat([action.topic])
            };

        case REMOVE_SELECTED_TOPIC:
            return {
                ...state,
                selectedTopics: state.selectedTopics.filter(el => el !== action.topic)
            };

        case SELECT_ALL_TOPICS:
            return {
                ...state,
                selectedTopics: state.mostCommonTopics.map(el => el)
            };

        case DESELECT_ALL_TOPICS:
            return {
                ...state,
                selectedTopics: []
            };
        case SELECT_TOPIC:
            const { selectedTopic } = action;
            return {
                ...state,
                selectedTopic
            }
        case DESELECT_TOPIC:
            return {
                ...state,
                selectedTopic: null
            }
        default:
            return state;
    }
}
