/**
 * This reducer is responsible for isAgreementAccepted part of the store
 */

import {ACCEPT_AGREEMENT} from "../actions/actionTypes";

const initialState = localStorage.getItem('SilkContractsAcceptAgreement') === 'true';

export default (state=initialState, action) => {
    if (action.type === ACCEPT_AGREEMENT) {
        return true;
    }
    return state;
}
