import React from 'react';
import configs from '../../configs';
import i18next from "i18next";
const { PREFIX } = configs;

class AgreementModal extends React.Component {
    handleAgree = event => {
        event.preventDefault();
        this.props.agreeCallback();
        this.props.close();
    };

    handleReject = event => {
        event.preventDefault();
        this.props.rejectCallback();
        this.props.close();
    };


    render() {
        return (
            <>
                <div className="modal-body">
                    {i18next.t('LinkLicenseAgreement')} <a href={`${PREFIX}/assets/agreement.pdf`} target="_blank"  rel="noopener noreferrer" >{i18next.t('LicenseAgreement')}</a>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="app-button"
                        onClick={this.handleAgree}>
                        {i18next.t('ButtonAccept')}
                    </button>
                    <button
                        type="button"
                        className="app-button"
                        onClick={this.handleReject}>
                        {i18next.t('ButtonReject')}
                    </button>
                </div>
            </>
        );
    }
}

export default AgreementModal;
