import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './styles.css';

class Node extends React.Component {
    getClassName = () =>
        classNames(
            'react-collapsible-tree-node',
            { 'react-collapsible-tree-node-selected': this.props.selected },
            'react-collapsible-tree-node-parent',
            this.props.className
        );

    handleClick = () => {
        const { id, onSelection } = this.props;
        onSelection(id);
    };

    // ConnectedNode is normally not allowed to be used before being declared
    // eslint:disable
    renderChild = childId => (
        <li key={childId} style={{ listStyleType: 'none' }}>
            <ConnectedNode
                onSelection={this.props.onSelection}
                className={this.props.className}
                id={childId}
                indent={this.props.indent + 10}
                topicId={this.props.topicId}
            />
        </li>
    );
    // eslint:enable

    render() {
        const { children, showChildren, name, link } = this.props;
        const parentNode = (<button role="menuitem" className="menuitem" onClick={this.handleClick}>
          {name}
        </button>);

        const leafNode = (
            <a onClick={this.handleClick}
               href={link}
               target="_blank"
               rel="noopener noreferrer"
               className="menuitem-link"
            >
                <span>{name}</span>
            </a>);

        return (
            <div
                style={{ paddingLeft: this.props.indent }}
                className={this.getClassName()}
            >
                <div className="d-flex justify-content-start">
                    { showChildren && children && <i className="arrow down" style={{ marginTop: 10 }} />}
                    { !showChildren && children && <i className="arrow right" style={{ marginTop: 10 }} />}
                    { children ? parentNode : leafNode}
                </div>
                {showChildren && children && <ul style={{ paddingLeft: 10 }}>{children.map(this.renderChild)}</ul>}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) =>  typeof state.collapsibleTree[ownProps.topicId] !== 'undefined' ? state.collapsibleTree[ownProps.topicId][ownProps.id] : state.collapsibleTree;

const ConnectedNode = connect(mapStateToProps)(Node);
export default ConnectedNode;
