export const SET_TOPIC_MAPPINGS = 'SET_TOPIC_MAPPINGS';
export const SET_TOPIC_COLORS = 'SET_TOPIC_COLORS';
export const SET_SETTINGS = 'SET_SETTINGS';

export const ADD_SELECTED_TOPIC = 'ADD_SELECTED_TOPIC';
export const REMOVE_SELECTED_TOPIC = 'REMOVE_SELECTED_TOPIC';
export const SELECT_ALL_TOPICS = 'SELECT_ALL_TOPICS';
export const DESELECT_ALL_TOPICS = 'DESELECT_ALL_TOPICS';
export const SELECT_TOPIC = 'SELECT_TOPIC';
export const DESELECT_TOPIC = 'DESELECT_TOPIC';

export const ACCEPT_AGREEMENT = 'ACCEPT_AGREEMENT';

export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const SET_DEFAULT_DOCUMENT_TYPE = 'SET_DEFAULT_DOCUMENT_TYPE';

export const TOPICIFY = 'TOPICIFY';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'; // shows notification after topics extraction
export const CLEAR = 'CLEAR'; // clears data in store before new analysis

// Collapsible tree actions
export const TREE_INITIALIZE = 'TREE_INITIALIZE';
export const TREE_TOGGLE_CHILDREN = 'TREE_TOGGLE_CHILDREN';
export const TREE_UPDATE_STORE = 'TREE_UPDATE_STORE';
export const TREE_SELECT_NODE = 'TREE_SELECT_NODE';
export const TREE_UNSELECT = 'TREE_UNSELECT';
