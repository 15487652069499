import {SET_SETTINGS} from '../actions/actionTypes';

const initialSettings = {
    threshold: 0.2,
    fragmentsSize: 1,
    segmentLength: 5,
    segmentType: 'Sentence',
    maxSplits: 15,
    contextWindow: 24,
    contextGap: 12
};

export default (state=initialSettings, action) => {
    if (action.type === SET_SETTINGS) {
        return action.settings;
    }
    return state;
}
