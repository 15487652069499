import {
    TREE_INITIALIZE,
    TREE_SELECT_NODE,
    TREE_TOGGLE_CHILDREN,
    TREE_UNSELECT,
    TREE_UPDATE_STORE
} from '../actions/actionTypes';

const node = (state, action) => {
    switch (action.type) {
        case TREE_TOGGLE_CHILDREN:
            return {
                ...state,
                showChildren: !state.showChildren,
                selected: true
            };
        case TREE_SELECT_NODE:
            return {
                ...state,
                selected: true
            };
        case TREE_UNSELECT:
            return {
                ...state,
                selected: false
            };
        default:
            return state;
    }
};

export default (state = {}, action) => {
    const { topicId, nodeId } = action;

    if (action.type === TREE_INITIALIZE) {
        return action.collapsibleTree;
    }

    if (action.type === TREE_UPDATE_STORE) {
        return {
            ...state,
            [topicId]: action.store
        }
    }

    if (typeof nodeId === 'undefined') {
        return state;
    }

    const newState = topicId ? {
        ...state,
        [topicId]: {
            ...state[topicId],
            [nodeId]: node(state[topicId][nodeId], action),
            selected: nodeId
        }
    } : {
        ...state,
        [nodeId]: node(state[nodeId], { nodeId: action.nodeId }),
        selected: nodeId
    };

    if (typeof topicId !== 'undefined' && state[topicId].selected !== nodeId && (action.type === TREE_TOGGLE_CHILDREN || action.type === TREE_SELECT_NODE)) {
        const oldSelectedNodeId = state[topicId].selected;
        return {
            ...newState,
            [topicId]: {
                ...state[topicId],
                [oldSelectedNodeId]: node(state[oldSelectedNodeId], {type: TREE_UNSELECT})
            }
        };
    }

    if (typeof topicId === 'undefined' && state.selected !== nodeId && (action.type === TREE_TOGGLE_CHILDREN || action.type === TREE_SELECT_NODE)) {
        const oldSelectedNodeId = state.selected;
        return {
            ...newState,
            [oldSelectedNodeId]: node(state[oldSelectedNodeId], {type: TREE_UNSELECT})
        };
    }

    return newState;
};
