import React from 'react';
import {connect} from 'react-redux';
import {addSelectedTopic, deselectAllTopics, removeSelectedTopic, selectAllTopics} from '../../../actions';
import { arrayIntersect } from '../../../utils';
import i18next from 'i18next';
import './styles.css';

import TopicDetails from "./components/topic-details";

const mapStateToProps = (state) => ({
    text: state.topicInfo.text,
    topicMapping: state.topicInfo.topicMapping,
    topics: state.topicInfo.topics,
    initiallySelectedTopics: state.topicInfo.initiallySelectedTopics,
    mostCommonTopics: state.topicInfo.mostCommonTopics,
    selectedTopics: state.topicInfo.selectedTopics,
    topicColors: state.topicColors
});


class Topics extends React.Component {

    handleClick(clickedTopic) {
        if (this.props.selectedTopics.includes(clickedTopic)) {
            this.props.removeSelectedTopic(clickedTopic);
        } else {
            this.props.addSelectedTopic(clickedTopic);
        }
    };

    getButtonColor(currentTopic) {
        if (this.props.selectedTopics.indexOf(currentTopic) === -1) return null;
        if (this.props.topicColors) {
            return this.props.topicColors[currentTopic];
        }
        return 'gray';
    };


    handleSelectAll(event) {
        if (event.target.checked) {
            this.props.selectAllTopics();
        } else {
            this.props.deselectAllTopics();
        }
    };

    render() {
        const initiallySelectedTopics = arrayIntersect(this.props.mostCommonTopics, this.props.initiallySelectedTopics);
        const topicName = topicId => <span className="pl-1">{this.props.topicMapping[topicId].split()}</span>;
        const allTopicsSelected = this.props.mostCommonTopics.length === this.props.selectedTopics.length;

        return (
            <>
                {this.props.mostCommonTopics &&
                <div id="most_common_topics">

                    {/* TODO: Actually reuse this. Better to move custom checkbox to separate component. */}

                    <div>
                        <ul className="list-group">
                            <div className="d-flex justify-content align-items-center topic-detail no-select">
                                <label className="custom-control m-1 custom-checkbox-container">
                                    <input type="checkbox"
                                           id="selectAll"
                                           readOnly
                                           checked={allTopicsSelected}
                                           onChange={(event) => this.handleSelectAll(event)}
                                    />
                                    <span
                                        className="checkmark"
                                        style={allTopicsSelected ? { backgroundColor: "#3E5570" } : {}}
                                    />
                                </label>
                                <div style={{ width: '100%', minHeight: 55, textAlign: 'left' }} className="d-flex align-items-center justify-content-left">
                                    { i18next.t('LabelSelectAll') }
                                </div>
                            </div>

                            { initiallySelectedTopics && initiallySelectedTopics.map(el => (
                                <TopicDetails
                                    key={this.props.topicMapping[el]}
                                    id={this.props.topicMapping[el]}
                                    topicId={el}
                                    checked={this.props.selectedTopics.includes(el)}
                                    onClick={() => this.handleClick(el)}
                                    backgroundColor={this.getButtonColor(el)}
                                >
                                {topicName(el)}
                                </TopicDetails>
                            ))}

                            {this.props.mostCommonTopics
                                .filter(topic => !this.props.initiallySelectedTopics.includes(topic))
                                .map(el => (
                                    <TopicDetails
                                        key={this.props.topicMapping[el]}
                                        id={this.props.topicMapping[el]}
                                        topicId={el}
                                        checked={this.props.selectedTopics.includes(el)}
                                        onClick={() => this.handleClick(el)}
                                        backgroundColor={this.getButtonColor(el)}
                                        topicNumber={el}
                                    >
                                    {topicName(el)}
                                    </TopicDetails>
                                ))}
                        </ul>
                    </div>
                </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, { addSelectedTopic, removeSelectedTopic, selectAllTopics, deselectAllTopics })(Topics)
