import React from 'react';
import {connect} from 'react-redux';
import {
    setTopicColors,
    setDocumentType,
    clear,
    topicify,
    showNotification,
    selectTopic,
    deselectTopic
} from '../actions';
import {argmax, arrayIntersect, arraysEqual, filter, generateKey, generateRandomColor} from '../utils';
import { mapLinesToOriginalTextPositions } from './helper';
import Modal from '../modal';
import NewAnalysisModal from '../modal/new-analysis-modal';
import { store } from 'react-notifications-component';
import i18next from 'i18next';
import './styles.css';

import config from '../configs';
import Popover from '../shared/popover';
const { COLORS, COMPONENTS, DOC_TYPE_ENABLED } = config;

class Content extends React.Component {
    state = {
        topic2color: {},
        newAnalysisModalOpened: false,
    };

    static getNewColor(colorNumber) {
        return (colorNumber < COLORS.length) ? COLORS[colorNumber] : generateRandomColor();
    }

    constructor(props) {
        super(props);
        this.moveToNextAllowed = this.moveToNextAllowed.bind(this);
        this.moveToPrevAllowed = this.moveToPrevAllowed.bind(this);
        this.topicRefs = []; // this array stores all selected topics Refs so we can easily scroll to them
    }


    componentDidMount() {
        // establish topic-color mapping
        this.setState({ topic2color: this.generateTopic2ColorMapping() }, () => {
            this.props.setTopicColors(this.state.topic2color);
            if (this.props.topics?.length) {
                this.props.selectTopic(0);
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!arraysEqual(prevProps.mostCommonTopics, this.props.mostCommonTopics)) {
            this.setState({ topic2color: this.generateTopic2ColorMapping() }, () => {
                this.props.setTopicColors(this.state.topic2color);
            });
        }

        if (!COMPONENTS.notificationsEnabled) return;

        if (this.props.notificationShown && this.props.initiallySelectedTopics.length) {
            const initiallySelectedTopics = arrayIntersect(this.props.initiallySelectedTopics, this.props.mostCommonTopics);

            if (this.props.initiallySelectedTopics && initiallySelectedTopics.length === this.props.initiallySelectedTopics.length) {
                store.addNotification({
                    title: i18next.t('TitleNotification'),
                    message: i18next.t('MsgAllEssentialConditionsFulfilled'),
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 60*1000,
                        onScreen: true
                    }
                });
            } else if (this.props.initiallySelectedTopics) {
                const notSelectedTopics = this.props.initiallySelectedTopics.filter(el => !this.props.mostCommonTopics.includes(el));
                const notSelectedTopicsNames = notSelectedTopics.map(idx => this.props.topicMapping[idx]);
                store.addNotification({
                    title: i18next.t('TitleNotification'),
                    message: i18next.t('MsgAllEssentialConditionsNotFulfilled', { letter: notSelectedTopics.length > 1 ? 'ю' : 'е', conditions: notSelectedTopicsNames.join(', ').toUpperCase()}),
                    type: "warning",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 60*1000,
                        onScreen: true
                    }
                });
            }
        }
        // we haven't shown the notification because there were no initially selected topics (document was classified as "Others")
        if (this.props.notificationShown) {
            this.props.showNotification(false);
        }
    }

    generateTopic2ColorMapping() {
        const topic2color = {};
        let colorNumber = 0;
        this.props.topics && this.props.topics.forEach(topicInfo => {
            const topicInd2Sim = topicInfo[1];
            Object.keys(topicInd2Sim).forEach(ind => {
                const index = Number.parseInt(ind);
                if (!(index in topic2color)) {
                    topic2color[index] = Content.getNewColor(colorNumber);
                    colorNumber += 1;
                }
            });
        });
        return topic2color;
    }

    getTopicColor(topicInfo) {
        const topicInd2Sim = this.filterSelectedTopicColors(topicInfo);
        const maxSimInd = argmax(topicInd2Sim);
        return this.state.topic2color[maxSimInd];
    }

    filterSelectedTopicColors(topicInfo) {
        const topicInd2Sim = topicInfo[1];
        return filter(topicInd2Sim, ind =>  this.props.selectedTopics.indexOf(Number.parseInt(ind)) > -1, true);
    }

    generateTooltip(data) {
        const topic_ind = Object.keys(data[1]).map(el => Number(el));
        const topic_weights = topic_ind.map(ind => parseFloat(data[1][ind]).toFixed(3));
        const topic_words = topic_ind.map(ind => this.props.topicMapping[ind]);
        const info = topic_ind.map((ind, i) => [ind, topic_weights[i], topic_words[i]]).sort((a, b) => b[1] - a[1]);

        return info
            .map(el => `${el[1]} - ${el[2].substring(0, el[2].length)}`)
            .join('\n');
    }

    createTopicRef(currentTopicIndex) {
        const ref = React.createRef();
        this.topicRefs[currentTopicIndex] = ref;
        return ref;
    }

    // to be precise, it's actually called a clause
    renderTopic(currentMark, currentTopicIndex, topicRef) {
        const tooltip = this.generateTooltip(this.props.topics[currentTopicIndex]);
        const isSelected = this.props.selectedTopic === currentTopicIndex;
        return (
            <Popover
                key={generateKey(currentMark)}
                isVisible={isSelected && COMPONENTS.moveButtonsEnabled}
                text={tooltip}
            >
                <span
                    className={`mark ${isSelected && COMPONENTS.moveButtonsEnabled ? 'selected-mark' : ''}`}
                    style={{ backgroundColor: this.getTopicColor(this.props.topics[currentTopicIndex]) }}
                    title={tooltip}
                    onClick={COMPONENTS.moveButtonsEnabled ? () => this.toggleSelectedTopic(currentTopicIndex) : null}
                    ref={topicRef}
                >
                        {currentMark}
                </span>
            </Popover>
        );
    }

    // todo refactor it to make it testable
    // to be more precise, it's actually a JSX
    generateHighlightedHTML() {
        if (!this.props.content) return null;

        let currentTopicIndex = 0;
        let currentMark;

        const lines = this.props.content.split('\n');
        const enhancedLines = mapLinesToOriginalTextPositions(lines);

        return enhancedLines.map(line => {
            const paragraphLength = line.content.length;
            let children = [];
            let prevStartInParagraph = 0;

            let { start, end } = currentTopicIndex < this.props.topics.length ?
                this.props.topics[currentTopicIndex][0] : { start: line.start, end: line.end };

            while (end <= line.end && start >= line.start && currentTopicIndex < this.props.topics.length) {

                const startInParagraph = start - line.start;
                const endInParagraph = end - line.start;

                if (startInParagraph > prevStartInParagraph) {
                    children.push(line.content.substring(prevStartInParagraph, startInParagraph));
                }
                currentMark = line.content.substring(startInParagraph, endInParagraph);
                const topicRef = this.createTopicRef(currentTopicIndex);

                children.push(this.renderTopic(currentMark, currentTopicIndex, topicRef));

                prevStartInParagraph = endInParagraph;
                currentTopicIndex += 1;
                if (currentTopicIndex < this.props.topics.length) {
                    start = this.props.topics[currentTopicIndex][0].start;
                    end = this.props.topics[currentTopicIndex][0].end;
                }
            }
            // todo 25.11.19 this is not a complete fix, just a workaround
            // we have a segment with \n inside. we need to split it
            if (start >= line.start && start <= line.end && end > line.end) {
                currentMark = line.content.substring(start - line.start);
                const topicRef = this.createTopicRef(currentTopicIndex);

                children.push(this.renderTopic(currentMark, currentTopicIndex, topicRef));

                start += line.end;
                currentTopicIndex += 1;
            }

            if (end !== line.end && prevStartInParagraph < paragraphLength) {
                children.push(line.content.substring(prevStartInParagraph, paragraphLength));
            }

            if (end === line.end && start === line.start && currentTopicIndex < this.props.topics.length) {
                children.push(line.content);
            }

            return <p key={generateKey(line.content)}>{children.length ? children : line.content}</p>;
        });
    }

    handleDocumentTypeChange(docType) {
        this.props.setDocumentType(docType);
        this.props.topicify('/extractTopicsInText',  this.props.content);
    }

    hideNewAnalysisModal = () => {
        this.setState({newAnalysisModalOpened: false})
    };

    toggleSelectedTopic(topicIdx) {
        if (this.props.selectedTopic !== topicIdx) {
            this.props.selectTopic(topicIdx);
        } else {
            this.props.deselectTopic();
        }
    }

    moveToPrevAllowed() {
        return this.props.selectedTopic != null && this.props.selectedTopic !== 0;
    }

    moveToNextAllowed() {
        return this.props.selectedTopic != null && this.props.selectedTopic !== this.props.topics.length - 1;
    }

    moveToPrevTopic() {
        const prevTopic = this.props.selectedTopic - 1;
        if (prevTopic < 0) return;
        this.props.selectTopic(prevTopic);
        this.scrollToView(this.topicRefs[prevTopic]);
    }

    moveToNextTopic() {
        const nextTopic = this.props.selectedTopic + 1;
        if (nextTopic >= this.props.topics.length) return;
        this.props.selectTopic(nextTopic);
        this.scrollToView(this.topicRefs[nextTopic]);
    }

    scrollToView(ref) {
        ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }

    render() {
        return (
            <>
                <div className="d-flex justify-content-between" style={{ marginTop: 50 }}>
                    <div className="position-fixed" style={{ background: 'white', left: 0, right: 300, zIndex: 500 }}>

                        <div className="d-flex justify-content-between">
                            <h3 className="mt-2 ml-3 header">{ `${i18next.t('Result')}: ` }</h3>  { DOC_TYPE_ENABLED && this.props.docType }
                            <div className="d-flex align-items-center">
                                {COMPONENTS.moveButtonsEnabled &&
                                    <>
                                        <i
                                            className={`fa fa-arrow-left  mt-1 mr-2 arrow-left ${!this.moveToPrevAllowed() ?  'arrow-disabled': ''}`}
                                            title={this.moveToPrevAllowed() ? i18next.t('MovePrev') : i18next.t('MoveDisabled')}
                                            onClick={this.moveToPrevAllowed() ? () => this.moveToPrevTopic() : () => {}}
                                        />
                                        <i
                                            className={`fa fa-arrow-right mt-1 mr-3 arrow-right ${!this.moveToNextAllowed() ? 'arrow-disabled' : ''}`}
                                            title={this.moveToNextAllowed() ? i18next.t('MoveNext') : i18next.t('MoveDisabled')}
                                            onClick={this.moveToNextAllowed() ? () => this.moveToNextTopic() : () => {}}
                                        />
                                    </>
                                }
                                {COMPONENTS.changeTypeButtonVisible &&
                                <button
                                    className="mt-2 mr-2 app-button"
                                    onClick={() => this.setState({ newAnalysisModalOpened: true })}
                                >
                                    {i18next.t('ButtonChangeType')}
                                </button>
                                }
                                <button
                                    className="mt-2 mr-3 app-button"
                                    onClick={() => this.props.clear()}>
                                    {i18next.t('ButtonMakeNewAnalysis')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="text" className="m-3 p-3" style={{ whiteSpace: 'pre-line', position: 'absolute', top: 90 }}>
                    { this.props.topics.length === 0 ? this.props.content : this.generateHighlightedHTML() }
                </div>
                {
                    this.state.newAnalysisModalOpened &&
                    <Modal
                        close={this.hideNewAnalysisModal}
                        iconStyles="fa fa-2x fa-question-circle"
                        title={i18next.t('TitleChangeDocumentType')}
                    >
                        <NewAnalysisModal
                            agreeCallback={(docType) => this.handleDocumentTypeChange(docType)}
                            rejectCallback={this.hideNewAnalysisModal}
                            close={this.hideNewAnalysisModal}
                        />
                    </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    topicMapping: state.topicInfo.topicMapping,
    content: state.topicInfo.text,
    topics: state.topicInfo.topics,
    docType: state.docType,
    mostCommonTopics: state.topicInfo.mostCommonTopics,
    initiallySelectedTopics: state.topicInfo.initiallySelectedTopics,
    selectedTopics: state.topicInfo.selectedTopics,
    selectedTopic: state.topicInfo.selectedTopic,
    notificationShown: state.showNotification
});

export default connect(mapStateToProps, { setTopicColors,
    clear,
    setDocumentType,
    topicify,
    showNotification,
    selectTopic, deselectTopic })(Content)
