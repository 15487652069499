import React  from 'react';
import { connect } from 'react-redux';
import textSegmentation from './apis/textSegmentation';
import Content from './content';
import Sidebar from './sidebar';
import UserInputArea from './user-input-area';
import TopMenuNavbar from './top-menu-navbar';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import config from './configs/config';

import { setDefaultDocumentType, setDocumentType, setDocumentTypes } from './actions';


const mapStateToProps = (state) => ({
    topics: state.topicInfo.topics
});

class App extends React.Component {

    componentDidMount () {
        const title = document.createElement("title");
        title.innerText = config.title;

        const description = document.createElement("meta");
        description.name =  'description';
        description.content =  config.description;

        document.head.appendChild(title);
        document.head.appendChild(description);

        this.retrieveDocumentTypes();
    }

    retrieveDocumentTypes() {
        textSegmentation.get('/getDocumentTypes')
            .then(response => {
                const names = response.data.names;
                const defaultIdx = response.data.defaultDocTypeIdx;

                this.props.setDocumentTypes(names || []);
                this.props.setDefaultDocumentType(names[defaultIdx] || '');
                this.props.setDocumentType(names[defaultIdx] || '');
            });
    }

    render () {
        return  (
            <>
              <Sidebar />
              <main id="content">
                  <TopMenuNavbar />
                  <div>
                      { this.props.topics && <Content/> }
                      { !this.props.topics && <UserInputArea /> }
                  </div>
              </main>
              <ReactNotification />
            </>
        )
    }
}

export default connect(mapStateToProps, { setDocumentTypes, setDocumentType, setDefaultDocumentType })(App);
