import React from 'react';
import styles from './styles.module.css';

const Popover = ({ children, isVisible, text }) => {
    const childrenRef = React.createRef();
    const popoverMessageRef = React.createRef();

    return (
        <span className={styles['popover-component']}>
            <span ref={childrenRef}>{children}</span>
            <span className={`${styles.popover} ${ isVisible ? styles.pop : ''}`} ref={popoverMessageRef}>
                {text}
            </span>
        </span>
    );
};

export default Popover;
