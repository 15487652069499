const toggleItemsToId = (items, id, currentlySelected) => {
    let newItems;
    if (currentlySelected === null || currentlySelected === undefined) {
        newItems = {
            ...items,
            [id]: {
                ...items[id],
                selected: true,
                showChildren: !items[id].showChildren
            }
        };
    } else {
        newItems = {
            ...items,
            [currentlySelected]: {
                ...items[currentlySelected],
                selected: false
            },
            [id]: {
                ...items[id],
                selected: true,
                showChildren: !items[id].showChildren
            }
        };
    }

    let { parentid } = items[id];
    while (parentid) {
        const parent = newItems[parentid];
        newItems = {
            ...newItems,
            [parentid]: {
                ...parent,
                showChildren: true
            }
        };
        // eslint-disable-next-line
        parentid = parent.parentid;
    }

    return newItems;
};

export default {
    toggleItemsToId
};
