/**
 * This reducer is responsible for the documentTypes part of store.
 * Do not confuse it with docType!
 * Document types are returned from the server.
 * Nothing can be returned as well (in this case there is an empty array in the store).
 */

import {SET_DOCUMENT_TYPES} from '../actions/actionTypes';

export default (state=[], action) => {
    if (action.type === SET_DOCUMENT_TYPES) {
        return action.documentTypes;
    }
    return state;
}
