/**
 * This reducer is responsible for the docType part of store.
 * docType is a returned from the server document type
 */

import {SET_DOCUMENT_TYPE} from '../actions/actionTypes';

export default (state='', action) => {
    if (action.type === SET_DOCUMENT_TYPE) {
        return action.docType;
    }
    return state;
}
